var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Show_Component',{attrs:{"path":"common/forms/Input","currentComponent":_vm.composant_input,"params":{
			field: 'nom',
			label: 'Nom',
			class: 'haut no_mini_label'
		},"notes":"\n\t\t\tAjouter la class haut pour avoir un champ de taille standard\n\t\t\tAjouter la class no_mini_label pour avoir un champ sans label intégré\n\t\t\tPour un champ date : <Input type='date' v-model='date' class='haut no_mini_label' pictoRight='IconCalandarDays' />\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/Input","currentComponent":_vm.composant_statuts,"params":{
			element: {
				identifiant: '1234567890',
				current_status: 'active'
			},
			field : 'current_status',
			target: 'companies'
		},"notes":"\n\t\t\tL'affichage du bouton de paramétrage des statuts est conditionné par la valeur du getter 'canManageStatuses'\n\t\t\tTarget peux prendre les valeurs : 'companies', 'parcels'. La liste des statuts affichée dépend de la valeur de target.\n\t\t\tField permet de préciser le champ de element sur lequel chercher la valeur. Par défaut, c'est 'current_status'.\n\t\t"}}),_c('Show_Component',{attrs:{"path":"common/forms/ContentEditable","currentComponent":_vm.composant_contenteditable,"params":{
			always_editable: true,
			placeholder    : 'Votre contenu personnalisé ici ...',
			vmodel         : 'ContentEditable always_editable'
		},"notes":"always_editable: true pour garder active visuellement la zone éditable. Sinon, la zone ne s'active que lorsqu'on clique dedans"}}),_c('Show_Component',{attrs:{"path":"common/forms/ContentEditable","currentComponent":_vm.composant_contenteditable,"params":{
			always_editable   : false,
			placeholder       : 'Votre contenu personnalisé ici ...',
			data              : 'ContentEditable',
			activable_on_click: true
		},"notes":"always_editable: true pour garder active visuellement la zone éditable. Sinon, la zone ne s'active que lorsqu'on clique dedans\n\t\tAttention, si le parent est en display: flex, ça peut poser problème"}}),_c('Show_Component',{attrs:{"path":"common/forms/ContentEditable","currentComponent":_vm.composant_contenteditable,"params":{
			always_editable   : true,
			placeholder       : 'Votre contenu personnalisé ici ...',
			data              : 'ContentEditable inline',
			inline: true
		},"notes":"always_editable: true pour garder active visuellement la zone éditable. Sinon, la zone ne s'active que lorsqu'on clique dedans\n\t\tAttention, si le parent est en display: flex, ça peut poser problème"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }