import axios from '../../../common/axios.js'
import _ from 'lodash'
// import html2canvas from "html2canvas"
// import domtoimage from "dom-to-image-more"
// import * as htmlToImage from 'html-to-image';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { domToPng } from 'modern-screenshot'
import jsPDF from 'jspdf' 
import MenuSousMenu from '../../../common/ui/menu_sous-menu.vue'
import Dates from '../../../common/mixins/Dates.js'

export default {
	mixins: [Dates],
	data() {
		return {
			open: false,
			img: null
		}
	},
	props: [],
	created(){
		
	},
	mounted(){
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector(".c-results-page")?.addEventListener('click', this.close_modal);
	},
	beforeDestroy: function() {
		document.querySelector(".c-results-page")?.removeEventListener('click', this.close_modal);
	},
	methods: {
		close_modal(event) {
			this.open = false
		},
		set_mode(mode){
			this.$store.dispatch('set_mode_affichage', mode)
			
			if(mode == 'graph'){
				this.$store.dispatch('openGraph')
			}else{
				this.$store.dispatch('openCarte')
			}
		},

		//AS--> Methode printScreen
		//AS--> Problèmes : 
		//AS--> - Affichage destructuré
		//AS--> - La carte s'étire à moitié sans rendre les tuiles des parties nouvellement affichées
		screenPdfDisplayMedia_0(){
			window.print()
		},

		//AS--> Methode getDisplayMedia
		//AS--> Problèmes : 
		//AS--> - Demande le partage d'un écran ou d'une fenêtre qu'il faut choisir
		//AS--> - L'image capturée inclut la fenêtre de sélection de l'écran
		screenPdfDisplayMedia_1(){
			console.log('screenPdfDisplayMedia')
			const canvas  = document.createElement("canvas");

			const doc = new jsPDF({
					orientation: "landscape",
				});

			// Request media
			navigator.mediaDevices.getDisplayMedia()
				.then(stream => {
				  // Grab frame from stream
				  let track = stream.getVideoTracks()[0];
				  let capture = new ImageCapture(track);
				  capture.grabFrame().then(bitmap => 
				  {
				    // Stop sharing
				    track.stop();
				      
				    // Draw the bitmap to canvas
				    canvas.width = bitmap.width;
				    canvas.height = bitmap.height;
				    canvas.getContext('2d').drawImage(bitmap, 0, 0);
				      
				    const img = canvas.toDataURL("image/png");
					self.img = img
					console.log(img);
					doc.addImage(img,'JPEG',0,0,297,210);
					doc.save("sample.pdf");
				  });
				})
				.catch(e => console.log(e));
		},

		//AS--> Methode html2canvas
		//AS--> Problèmes : 
		//AS--> - L'image générée n'affiche pas les tuiles GoogleMaps
		screenPdfDisplayMedia_2(){
			let self = this

			this.$store.commit('UI_SET_SCREEN_PDF_ON', true);

			setTimeout(function(){

				let node = document.getElementById('app_content')
				
				const doc = new jsPDF({
					orientation: "landscape",
				});
				html2canvas(node, {foreignObjectRendering:true})
					.then(function (canvas) {
						self.$store.commit('UI_SET_SCREEN_PDF_ON', false);
						const img = canvas.toDataURL("image/png");
						self.img = img
						console.log(img);
						doc.addImage(img,'JPEG',0,0,297,210);
						doc.save("sample.pdf");
					})

			}, 50)
				
		},

		//AS--> Methode html-to-image
		//AS--> Problèmes : 
		//AS--> - Prblèmes de Cors, impossibilité d'accéder aux ressources distantes -> plante
		screenPdfDisplayMedia_3(){
			let self = this

			this.$store.commit('UI_SET_SCREEN_PDF_ON', true);

			let node = document.getElementById('app_content');

			htmlToImage.toPng(node)
				.then(function (dataUrl) {
					var img = new Image();
					img.src = dataUrl;
					document.body.appendChild(img);
				})
				.catch(function (error) {
					console.error('oops, something went wrong!', error);
				});
			

			setTimeout(() => {
				this.$store.commit('UI_SET_SCREEN_PDF_ON', false);
			}, 5000)
			
		},
		
		screenPdfDisplayMedia_4(){
			let self = this

			//AS--> !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
			//AS--> Spécifier le ratio de la fenêtre dans _annuaire.scss, sur la règle #app_content.screen-pdf-on
			//AS--> Indiquer un ratio déduisant les marges. Par exemple 297/210 sans marge, ou 287/200 avec une marge de 5 mm tout autour
			//AS--> !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

			this.$store.dispatch('modePdfOn', 'scale').then(() => {
				setTimeout(() => {
					domToPng(document.querySelector('#app_content'), {
						quality: 1,
						scale: 2
					}).then(dataUrl => {
						self.screenPdfPutImgInPdf(dataUrl)
						this.$store.commit('UI_SET_SCREEN_PDF_ON', false);
					})
				}, 5000)
			})
		},

		screenPdfPutImgInPdf(img_dataUrl){
			const doc = new jsPDF({
				orientation: "landscape",
			});
			let marge = 0 // mm
			doc.addImage(img_dataUrl,'PNG',marge,marge,297-marge*2,210-marge*2);
			//AS--> Création du nom du fichier sur la base du code du sourcing + date au format yyyy-mm-dd_hh-mm-ss
			let file_name = this.$store.getters.getSourcingCode + '_' + this.getFormattedDateTimeForFileName()
			doc.save(file_name+".pdf");
		},

		screenPngDisplayMedia_0(){
			this.$store.dispatch('modePdfOn', true).then(() => {
				setTimeout(() => {
					domToPng(document.querySelector('#app_content'), {
						quality: 1,
						scale: 2
					}).then(dataUrl => {
						//AS--> Fait télécharger l'image
						this.screenPngDownloadImg(dataUrl)
						this.$store.commit('UI_SET_SCREEN_PDF_ON', false);
					})
				}, 2000)
			})
		},

		screenPngDownloadImg(img_dataUrl){
			let file_name = this.$store.getters.getSourcingCode + '_' + this.getFormattedDateTimeForFileName()
			let a = document.createElement('a');
			a.href = img_dataUrl;
			a.download = file_name+".png";
			a.click();
		},

		exportXls(){
			this.$store.commit('UI_SET_EXPORT_ID', false)
			this.$store.commit('UI_SET_EXPORT_TYPE', 'csv')
			this.$store.dispatch('open_modal', 'export_list')
		},

		exportCrm(){
			this.$store.commit('UI_SET_EXPORT_ID', false)
			this.$store.commit('UI_SET_EXPORT_TYPE', 'crm')
			this.$store.dispatch('open_modal', 'export_list')
		},
		openPendingFlowModal(promise) {
			let self = this

			let trad_code = 'waste_dashboard'

			self.$store.dispatch('open_modal', {
				code: 'pending_api', params: {
					promise,					
					pending_title: 'modal_export_pending_title_' + trad_code,
					pending_message: 'modal_export_pending_message_' + trad_code,
					success_title: 'modal_export_success_title_' + trad_code,
					success_message: 'modal_export_success_message_' + trad_code,
					error_title: 'modal_export_error_title_' + trad_code,
					error_message: 'modal_export_error_message_' + trad_code,
					error_html: '<p>' + self.$t('modal_export_error_message_contact_' + trad_code) + '<br><a href="mailto:' + self.$t('modal_export_error_message_contact_mail_' + trad_code) + '">' + self.$t('modal_export_error_message_contact_mail_' + trad_code) + '</a></p>'
				}
			})
		},
		exportMatieres(){
			let self = this
			const promise = axios.post("api=inex/companies/export/xls/{view_code}/waste_dashboard", self.$store.getters.getFiltersForExport, {
				headers: {'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
					responseType: 'arraybuffer'
			}).then(function (response) {
				return Promise.resolve(response)
			}).catch(function (error) {
				return Promise.reject(error)
			})
			// .finally(function(){
			// 	self.$store.dispatch('open_modal', 'export_matieres_ok')
			// })

			this.openPendingFlowModal(promise)	
		}
	},
	computed:{
		modes(){
			return this.$store.state.ui.sourcing.modes_affichage.split(',')
		},
		menu_telecharger(){
			let menu = {
				menu: {
					picto: 'Download2',
					label: false
				},
				sousmenu: {
					list: []
				}
			}

			//AS--> Export en Xls
			if(this.$store.getters.canExportCSV){
				menu.sousmenu.list.push({
					picto: 'Xls',
					label: this.$t('statut_bar_export'),
					action: this.exportXls,
					width: 18,
					height: 18
				})
			}

			//AS--> Export Dans le CRM
			if(this.$store.getters.canExportCRM){
				menu.sousmenu.list.push({
					picto: 'Crm',
					label: this.$t('statut_bar_export_crm'),
					action: this.exportCrm,
					viewBox: '0 0 17 13',
					width: 17,
					height: 13
				})
			}

			//AS--> Export des matières
			if(this.$store.getters.canExportMatieres){
				menu.sousmenu.list.push({
					picto: 'Waste',
					label: this.$t('statut_bar_export_matieres'),
					action: this.exportMatieres,
					viewBox: '-2 0 52 46',
					width: 18,
					height: 18
				})
			}

			//AS--> Export en PDF
			// menu.sousmenu.list.push({
			// 	picto: 'Image',
			// 	label: this.$t('statut_bar_pdf'),
			// 	action: this.screenPdfDisplayMedia_4
			// })

			//AS--> Export en PNG
			menu.sousmenu.list.push({
				picto: 'Image',
				label: this.$t('statut_bar_png'),
				action: this.screenPngDisplayMedia_0
			})

			return menu
		}
	},
	components:{
		MenuSousMenu
	}
}