export default {
	methods: {
		assombrirCouleur(hex, pourcentage) {
            // Supprimer le caractère '#' si présent
            hex = hex.replace('#', '');
        
            // Convertir la couleur hexadécimale en valeurs RGB
            let r = parseInt(hex.substring(0, 2), 16);
            let g = parseInt(hex.substring(2, 4), 16);
            let b = parseInt(hex.substring(4, 6), 16);
        
            // Assombrir chaque composant en réduisant sa valeur
            r = Math.max(0, r - (r * pourcentage / 100));
            g = Math.max(0, g - (g * pourcentage / 100));
            b = Math.max(0, b - (b * pourcentage / 100));
        
            // Convertir les valeurs RGB assombries en hexadécimal
            r = Math.round(r).toString(16).padStart(2, '0');
            g = Math.round(g).toString(16).padStart(2, '0');
            b = Math.round(b).toString(16).padStart(2, '0');
        
            // Retourner la couleur assombrie en format hexadécimal
            return `#${r}${g}${b}`;
        }
	}
}