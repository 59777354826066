<template>
  <div
    class="input autocomplete v2"
    :class="['no_mini_label w100 select-' + _kebabCase(field), { picto: picto, 'opened': localOptions && localOptions.length, has_data: localChoice}]"
  >

    <input
      class            = "v2"
      type             = "text"
      :placeholder     = "getPlaceholder"
      :id              = "field"
      :name            = "field"
      v-model          = "search"
      @input           = "onInput"
      @keyup.enter     = "selectOnEnter()"
      :readonly        = "localChoice"
      @focus           = "input_focus = true"
      @blur            = "input_focus = false"
    />

    <div v-if="localChoice" class="affichage_value" v-html="i18n(localChoice)"></div>
    
    <div v-if="localOptions && localOptions.length" class='options' style="z-index: 1000">

      <ul>

        <li v-for="_option in localOptions" @click='select(_option)'>
          <icon-base v-if="_option.picto" viewBox="0 0 24 24" width="24" height="24"><component v-bind:is="'Icon'+_option.picto" /></icon-base>
          <span v-html="_option.label"></span>
        </li>

      </ul>

    </div>

    <span v-if='picto' class="picto">
      <icon-base :viewBox="viewBox" width="14" height="14"><component v-bind:is="picto" /></icon-base>
    </span>

    <span v-if="localChoice || search" class="vider" @click="empty_value()">
      <icon-base viewBox="0 0 23 23" width="14" height="14" ><IconClose2 /></icon-base>
    </span>

  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase"
import _ from "lodash"

export default {
  name: "Autocomplete",
  data() {
    return {
      opened: false,
      search: '',
      localOptions: this.options, // Nouvelle propriété locale
      localChoice: null,
      input_focus: false
    }
  },
  emits: ['update:search', 'update:choice'],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    url: String,
    field: String,
    placeholder: String,
    placeholder_focus: String,
    picto: {
      type: String,
      default: 'IconSearch'
    },
    viewBox: {
      type: String,
      default: '0 0 42 42'
    }
  },
  created() {
    
  },
  mounted(){
    //AS--> Ajout un événement sur le body pour fermer la modale
    document.querySelector("#app_content")?.addEventListener('click', this.clickOutside)
  },
  beforeDestroy: function() {
    document.querySelector("#app_content")?.removeEventListener('click', this.clickOutside)
  },
  watch: {
    options: {
      handler(newOptions) {
        this.localOptions = newOptions;
      },
      deep: true
    }
  },
  methods: {
    _kebabCase(field) {
      return kebabCase(field)
    },
    close() {
      this.localOptions = null;
    },
    clickOutside(event) {
      if (event.target.closest('.autocomplete') == null) this.close()
    },
    onInput(event) {
      this.$emit('update:search', event.target.value)
    },
    empty_value(){
      this.search = ''
      this.localChoice = null
      this.$emit('update:search', '')
      this.$emit('update:choice', null)
    },
    select(option) {
      if(option.value){ 
        this.localChoice = option.label
        this.search = ''
        this.$emit('update:choice', option);
        this.close();
      }
    },
    selectOnEnter(){
      if(this.localOptions && this.localOptions.length > 0 && this.localOptions[0].value){
        this.select(this.localOptions[0])
      }
    }
  },
  computed: {
    getPlaceholder() {
      //AS--> Si localChoice n'est pas vide, on n'affiche pas le placeholder
      if(this.localChoice) return ''
      if(this.input_focus) return this.placeholder_focus ? this.i18n(this.placeholder_focus) : this.i18n(this.placeholder)
      return this.i18n(this.placeholder)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
