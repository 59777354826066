import vueCustomScrollbar from 'vue-custom-scrollbar'
import _ from 'lodash'
import Multiselect from '../../common/forms/MultiSelect.vue'
import Slider from '../../common/forms/Slider.vue'
import Switcher from '../../common/forms/Switcher.vue'
import VueSlider from 'vue-slider-component'
import Siren from './filtres_composants/parcelles_proprietaires.vue'
import Loading from '../../common/ui/loading_inex.vue'
import SectionSwitch from '../../common/ui/section_switch.vue'

export default {
	data() {
		return {
			user_filters: {},
			favoris_filters: {},
			area_limit_ok: false,
			slider: undefined,
			sections: [
				{
					title: this.$t('parcelles_filtres_section_proprietaires'),
					show: 1,
					filters: [
						"proprietaires",
						"owner_siren",
						"statuses",
					]
				},
				{
					title: this.$t('parcelles_filtres_section_foncier'),
					show: 1,
					filters: [
						"surface",
						"individual_buildings_surface",
						"individual_buildings_height",
					]
				},
				{
					title: this.$t('parcelles_filtres_section_distances'),
					show: 1,
					filters: [
						"distance_autoroutes",
						"distance_habitations",
						"distance_nationales",
					]
				},
				{
					title: this.$t('parcelles_filtres_section_zonage'),
					show: 1,
					filters: [
						"zone",
						"information",
					]
				}
			]
		}
	},
	props:{
		params: {},
		reinit_call: 0,
		btn_reinit: true,
		menu_index: null
	},
	created(){
		//AS--> Charge les filtres iNex
		this.$store.dispatch('loadApiInexFilters')

		//AS--> Fixe le area_limit s'il est précisé dans les paramètres
		if(this.params && this.params.area_limit) this.$store.commit('PARCELLE_PARCELLES_AREA_LIMIT', this.params.area_limit);
	},
	watch:{
		"$store.state.ui.map_zone_google": function(after, before){
			if(this.area_limit_ok == false) this.area_limit_ok = true
			if(!_.isEqual(after, before)){
				if(this.$store.state.parcelle.menu_parcelles_affiche) this.$store.dispatch('loadParcelles')
				//AS--> Charge les filtres iNex
				this.$store.dispatch('loadApiInexFilters')
			}
		},
		reinit_call(){
			this.reset_filters()
		},
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
			if(before[this.menu_index]?.selectAll_index != after[this.menu_index]?.selectAll_index) this.toggle_filters()
		}
	},
	methods: {
		close_filter(){
			this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
		},
		update_filters(){
			//AS--> Retire les valeur null
			let filters = _.cloneDeep(this.user_filters)
			_.forEach(filters, function(val, key){
				if(val === null) delete filters[key]
			})
			this.user_filters = filters

			//AS--> Si on est en mode liste de parcelles, on ne conserve que les filtres de la liste (tous ceux commençant par liste_)
			if(this.$store.state.parcelle.parcelles_list){
				let filters_cleaned = {}
				_.forEach(this.user_filters, function(val, key){
					if(key.startsWith('liste_')) filters_cleaned[key.replace('liste_', '')] = val
				})
				//AS--> Envoi les filtres nettoyés au store
				this.$store.commit('PARCELLE_MENU_PARCELLES_FILTRES_LISTE', filters_cleaned);
	
				//AS--> Reload les parcelles
				if(this.$store.state.parcelle.menu_parcelles_affiche) this.$store.dispatch('loadParcelList', this.$store.state.parcelle.parcelles_list.list_id)
			}else{
				let filters_cleaned = {}
				_.forEach(this.user_filters, function(val, key){
					if(!key.startsWith('liste_')) filters_cleaned[key] = val
				})
				//AS--> Envoi les filtres nettoyés au store
				this.$store.commit('PARCELLE_MENU_PARCELLES_FILTRES', filters_cleaned);
				console.log(this.user_filters)
	
				//AS--> Reload les parcelles
				if(this.$store.state.parcelle.menu_parcelles_affiche) this.$store.dispatch('loadParcelles')
			}
		},
		getComponentType(type){
			if(type == 'select') return 'multiselect'
			if(type == 'numeric') return 'slider'
			if(type == 'switch') return 'switcher'
			return type
		},
		reset_filters(){
			this.user_filters = {}
			this.slider = 15
			this.updateOpacity(this.slider)
		},
		toggle_filters(){
			if(this.$store.state.parcelle.menu_parcelles_affiche){
				this.desactive_filters()
			}else{
				this.active_filters()
			}
		},
		active_filters(){
			this.$store.commit('PARCELLE_MENU_PARCELLES_AFFICHE', true)
			this.slider = 15
			this.updateOpacity(this.slider)
			if(this.area_limit_ok) this.$store.dispatch('loadParcelles')
		},
		desactive_filters(){
			this.$store.commit('PARCELLE_MENU_PARCELLES_AFFICHE', false)
			this.$store.commit('PARCELLE_PARCELLES_GEOJSON', false)
		},
		sliderChange(){
			let self = this
			let opacity = typeof this.slider == 'undefined' ? null : this.slider
			this.updateOpacity(this.slider)
		},
		updateOpacity(val){
			this.$store.dispatch('setParcellesOpacity', val)
		},
		sectionHasFilter(section){
			// Si section.filters contient au moins un filtre de filtres, alors on retourne true
			return _.some(this.filtres, function(filtre){
				return section.filters.includes(filtre.code)
			})
		},
		filterIsActive(filtre){
			// Si filtres contient une entrée avec le code de filtre, alors on retourne true
			return _.some(this.filtres, function(filtre_i){
				return filtre_i.code == filtre
			})
		},
		switchShowSection(section_index){
			let sections = this.sections
			sections[section_index].show = !sections[section_index].show
			this.sections = sections
		},
		getParamsFilter(filter){
			return _.find(this.filtres, function(filtre){
				return filtre.code == filter
			})
		},
		getTypeFilter(filter){
			return _.find(this.filtres, function(filtre){
				return filtre.code == filter
			}).type
		}
	},
	computed:{
		filtres(){
			let filtres = []
			if(this.$store.state.filters.inexapi_filters && this.$store.state.filters.inexapi_filters['parcelles'] && this.$store.state.filters.inexapi_filters['parcelles'].length){
				filtres = this.$store.state.filters.inexapi_filters['parcelles']
			}
			//AS--> Trouve le filtre avec le code statuses et le duplique pour en faire un filtre avec le code statuses_favoris
			let statuses = _.find(filtres, function(filtre){
				return filtre.code == 'statuses'
			})
			if(statuses){
				statuses = _.cloneDeep(statuses)
				statuses.code = 'liste_statuses'
				filtres.push(statuses)
			}	
			return filtres
		}
	},
	components: {
    	vueCustomScrollbar,
    	Multiselect,
    	Slider,
    	Switcher,
    	VueSlider,
		Siren,
		Loading,
		SectionSwitch
	}
}