import _ from 'lodash'

export default {
	data() {
		return {
			ready: false
		}
	},
	props: [],
	created(){
		
	},
	mounted(){
		if(this.configured){
			const hubspotScript = document.createElement("script");
		    hubspotScript.setAttribute("src", this.$store.state.ui.sourcing.hot_spot_chat_js);
		    hubspotScript.setAttribute("type", "text/javascript");
		    hubspotScript.setAttribute("id", "hs-script-loader");
		    document.head.appendChild(hubspotScript);

		    window.hsConversationsSettings = {};
			if (window.HubSpotConversations) {
				this.onConversationsAPIReady();
			} else {
				window.hsConversationsOnReady = [this.onConversationsAPIReady];
			}
		}else{
			console.log('##########################################');
			console.log('------ HubSpot non paramétré en BO -------');
			console.log('##########################################');
		}

		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector("#app_content").addEventListener('click', this.close);

		//AS--> Met en place une surveillance sur le changement de style de l'iframe
		this.observeStyleChange();

	},
	beforeDestroy: function() {
		document.querySelector("#app_content")?.removeEventListener('click', this.close);
	},
	methods: {
		onConversationsAPIReady() {
			this.ready = true
			console.log(`HubSpot Conversations API Ready`);
		},
		switchChat(){
			if(this.configured && window.HubSpotConversations && window.HubSpotConversations.widget){
				window.HubSpotConversations.widget.open();
			}
		},
		close(event){
			if(window.HubSpotConversations && window.HubSpotConversations.widget){
				window.HubSpotConversations.widget.close();
				if(document.getElementById("hubspot-messages-iframe-container")){
					document.getElementById("hubspot-messages-iframe-container").style.width = "10px";
					document.getElementById("hubspot-messages-iframe-container").style.height = "10px";
				}
			}
		},
		observeStyleChange(){
			let tag_id = 'hubspot-messages-iframe-container'
			
			//AS--> Si l'élément est déjà en place dans la page, on met en place l'écouteur
			if(document.getElementById(tag_id)){

				//AS--> On met en place un écouteur sur le changement de style de l'iframe
				let observer = new MutationObserver(function(mutations) {
				    mutations.forEach(function(mutationRecord) {
				        
				        //AS--> On modifie le style de l'iframe pour désactiver le forçage du placement en bottom effectué par Hubspot
				        target.style.bottom = "unset";

				    });    
				});

				//AS--> On met en place l'écouteur
				let target = document.getElementById(tag_id);
				observer.observe(target, { attributes : true, attributeFilter : ['style'] });

			//AS--> Sinon on attend 50ms et on relance la fonction
			}else{
				setTimeout(() => this.observeStyleChange(), 50);
			}
		}
	},
	computed:{
		configured(){
			return this.$store.state.ui.sourcing.hot_spot_chat_js && this.$store.state.ui.sourcing.hot_spot_chat_js.length > 0
		}
	},
	components:{
		
	}
}