import _ from 'lodash'

export default {
	data() {
		return {
			open: false
		}
	},
	props: [],
	created(){
		
	},
	mounted(){
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector(".c-results-page")?.addEventListener('click', this.close_modal);
	},
	beforeDestroy: function() {
		document.querySelector(".c-results-page")?.removeEventListener('click', this.close_modal);
	},
	methods: {
		close_modal(event) {
			let self = this
			setTimeout(function(){
				if (event.target.closest('#modes_affichage > button') == null) {
					self.close_modal()
				}
			}, 10)
		},
		close_modal(event) {
			this.open = false
		},
		set_mode(mode){
			this.$store.dispatch('set_mode_affichage', mode)
			
			if(mode == 'graph'){
				this.$store.dispatch('openGraph')
			}else{
				this.$store.dispatch('openCarte')
			}
		}
	},
	computed:{
		modes(){
			return this.$store.state.ui.sourcing.modes_affichage.split(',')
		}
	},
	components:{
		
	}
}