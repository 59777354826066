import _ from 'lodash'

//AS--> Eléments génériques
import ElementZone from './zone.vue'
import Onglets from '../ui/onglets.vue'

export default {
	data() {
		return {
			onglet_actif: 0
		}
	},
	props: ['elements'],
	created(){
		
	},
	watch:{
		elements(after, before){
			if(!before) this.onglet_actif = 0
		}
	},
	methods: {
		getElements(elements){
			let self = this

			if(elements.length){
				elements.forEach(function(element){

					//AS--> Si c'est un type wrapper
					if(element.content){
						if(!element.format) element.format = 'zone'
						element.content = self.getElements(element.content)

					//AS--> Sinon
					}else{
						if(!element.format) element.format = 'text'
						element.context = self.getContext
					}
				})
			}

			return elements
		},
		
	},
	computed:{
		onglets(){
			if(this.elements && this.elements.complements){
				return this.elements.complements
			}else if(this.elements && this.elements.properties && this.elements.properties.complements){
				return this.elements.properties.complements
			}
			return [];
		},
		onglets_menu(){
			let self = this
			let menu = []
			this.onglets.forEach(function(onglet, i){
				menu.push({
					code: i,
					label: self.i18n(onglet.name),
					tooltip: self.i18n(onglet.tooltip)
				})
			})
			return menu
		},
		getContext(){
			if(this.$store.state.parcelle.parcelle_on) return 'fiche_parcelle'
			if(this.$store.state.company.company?.id) return 'fiche_entreprise'
			return undefined
		}
	},
	components:{
		ElementZone,
		Onglets
	}
}