import _ from 'lodash'
import Utils from '../../common/utils.js';
import ContentEditable from '../forms/ContentEditable.vue';
import { debounce } from 'lodash'

export default {
	mixins: [Utils],
	data() {
		return {
			
		}
	},
	props: {
		element: {
			type: Object,
			default: {},
			required: true
		}
	},
	created(){
		
	},
	watch:{
		element: {
			deep: true,
			handler: debounce(function(newVal, oldVal) {
				if(this.element.context == 'fiche_parcelle' && this.element.field){
					this.$store.dispatch('updateFieldParcelle', {
						identifiant: this.$store.state.parcelle.parcelle_on_id,
						field      : this.element.field,
						value      : newVal.value
					})
				}
				if(this.element.context == 'fiche_entreprise' && this.element.field){
					this.$store.dispatch('entreprise_modifie_champ', {
						id: this.$store.state.company.company.id,
						champ: this.element.field,
						valeur: newVal.value,
						reload_modale: false,
						no_compagny_update: true
					})
				}
			}, 400)
		}
	},
	methods: {
		
	},
	computed:{
		
	},
	components:{
		ContentEditable
	}
}