import * as types from '../mutation-types'
import axios from '../../common/axios.js'
import _ from 'lodash'
import { ContentStructureUtils } from '../utils/content_structure.js'

// initial state
const state = {
	affichage: 'synthetique',
	loading: 0,
	loading_search: 0,
	feature_over: null,
	parcelle_on_id: null,			//AS--> Id de la parcelle sélectionnée
	parcelle_on_id_old: null,		//AS--> Id de la parcelle qui était sélectionnée avant
	parcelle_on: null,				//AS--> Données résumées de la parcelle
	parcelle_on_detailled: null,	//AS--> Donnée complètes de la parcelle
	favoris: [],

	menu_parcelles_filtres: [], 		//AS--> Filtres en mode pas liste de parcelles
	menu_parcelles_filtres_liste: [],	//AS--> Filtres en mode liste de parcelles
	menu_parcelles_affiche: true,
	parcelles_area_limit: 20,
	parcelles_geojson: false,
	parcelles_geojson_opacity: 8,
	parcelles_geojson_default_style: {
		fillColor :		'#FFC700',
		fillOpacity :	0.08,
		strokeColor :	'#FF7A00',
		strokeOpacity :	0.1
	},
	parcelles_geojson_axiostoken: false,

	parcelles_areaTooBig: false,

	parcelles_list_order: 'commune',
	parcelles_list_order_direction: 'asc',
	
	parcelles_lists: [],
	parcelles_list: null
}

// getters
const getters = {
	parcelleGeojson: function(){
		return 'test';
	},
	getTotalParcelleFavoris: (state) => {
		return state.favoris.length
	},
	hasFilters_parcelles: (state, getters, rootState) => (filtre) => {
		let filtres = _.cloneDeep(state.menu_parcelles_filtres)
		delete filtres.groupe
		return Object.keys(filtres).length > 0;
	},
	getNbrParcelles: (state) => {
		return state.parcelles_geojson?.features?.length ?? null
	},
	getParcelleContentByFormat: (state, getters) => (format, contents) => {
		if(!contents) contents = state.parcelle_on?.complements
		if(!contents) return null
		return ContentStructureUtils.getContentByFormat(contents, format)
	},

	getParcelleDetailledContentByFormat: (state, getters) => (format, contents) => {
		if(!contents) contents = state.parcelle_on_detailled?.complements
		if(!contents) return null
		return ContentStructureUtils.getContentByFormat(contents, format)
	},

	getParcelleContentModifiedByFormat: (state, getters) => (format, content, contents) => {
		if(!contents) contents = state.parcelle_on?.complements
		if(!contents) return null
		return ContentStructureUtils.getContentModifiedByFormat(contents, format, content)
	},

	/**
	 * Retourne les compléments détaillés dont un format est modifié par le contenu passé en paramètre
	 * @param {string} format Format du complément à modifier
	 * @param {object} content Contenu à appliquer au complément
	 * @param {array} contents Compléments détaillés à modifier. Facultatif.
	 * @returns {array}
	 */
	getParcelleDetailledContentModifiedByFormat: (state, getters) => (format, content, contents) => {
		if(!contents) contents = state.parcelle_on_detailled?.complements
		if(!contents) return null
		return ContentStructureUtils.getContentModifiedByFormat(contents, format, content)
	}
}

// actions
const actions = {
	setFeatureOver({ commit, state, rootState }, feature) {
		commit('PARCELLE_FEATURE_OVER', feature);
	},
	setParcelleOnId({ commit, state, rootState }, parcelle_id) {
		let self = this
		commit('PARCELLE_PARCELLE_ON_ID_OLD', parcelle_id ? parcelle_id : state.parcelle_on_id);
		commit('PARCELLE_PARCELLE_ON_ID', parcelle_id);
		commit('PARCELLE_PARCELLE_ON', null);
		commit('PARCELLE_PARCELLE_ON_DETAILLED', null);
		commit('PARCELLE_AFFICHAGE', 'synthetique');
		if(parcelle_id){
			commit('PARCELLE_ADD_LOADING');

			axios.get('api=inex/parcelles/{parcelle_id}?information_type=summary')
				.then(function (response) {
					commit('PARCELLE_REMOVE_LOADING');
					// console.log(response.data);
					if(response.data && response.data){
						commit('PARCELLE_PARCELLE_ON', response.data)
					}
				}).catch(function (error) {
					commit('PARCELLE_REMOVE_LOADING');

				})
		}
	},
	getParcelleOnDetailled({ commit, state, rootState }) {
		let self = this
		if(state.parcelle_on_id){
			axios.get('api=inex/parcelles/{parcelle_id}?information_type=all')
				.then(function (response) {
					if(response.data && response.data){
						console.log(response.data);
						commit('PARCELLE_PARCELLE_ON_DETAILLED', response.data)
					}
				}).catch(function (error) {

				})
		}
	},
	setParcelleOn({ commit, state, rootState }, parcelle) {
		commit('PARCELLE_PARCELLE_ON', parcelle);
	},
	setParcelleAffichage({ commit, state, rootState, dispatch }, affichage) {
		commit('PARCELLE_AFFICHAGE', affichage);
		if(affichage == 'detaille' && !state.parcelle_on_detailled){
			dispatch('getParcelleOnDetailled');
		}
	},
	addParcelleFavoris({ commit, state, rootState, dispatch }) {
		axios.post('api=inex/users/{user_email}/views/{view_code}/favorites/parcelles/{parcelle_id}')
			.then(function (response) {
				dispatch('getParcellesFavoris');
			}).catch(function (error) {

			})
	},
	delParcelleFavoris({ commit, state, rootState, dispatch }, parcelle_id) {
		axios.delete('api=inex/users/{user_email}/views/{view_code}/favorites/parcelles/'+parcelle_id)
			.then(function (response) {
				dispatch('getParcellesFavoris');
			}).catch(function (error) {

			})
	},
	getParcellesFavoris({ commit, state, rootState }) {
		axios.get('api=inex/users/{user_email}/views/{view_code}/favorites/parcelles')
			.then(function (response) {
				let favoris = []
				if(response.data.favorites && response.data.favorites.length){
					response.data.favorites.forEach(function(_favoris){
						if(_favoris.value){
							favoris.push(_favoris)
						}else{
							favoris.push({value:_favoris, name: _favoris})
						}
					})
				}
				commit('PARCELLE_PARCELLES_FAVORIS', favoris);
			}).catch(function (error) {

			})

	},
	loadParcelles({ commit, state, rootState }) {

		if(rootState.ui.screenPdfOn) return;
		// if(state.parcelles_list) return;

		// if(!rootState.ui.map_zone_google.geoJsonActif) return

		//AS--> Annule la précédente requête XHR si elle est encore en cours d'exécution
		if (state.parcelles_geojson_axiostoken) state.parcelles_geojson_axiostoken.cancel('Start new search, stop active search');

		//AS--> Stock un nouveau token d'exécution Axios
		commit('PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN', axios.CancelToken.source())
		commit('UI_ADD_LOADING_LAYER')
		commit('PARCELLE_SEARCH_ADD_LOADING');

		let vars = '?'+ encodeURI('geometry=' + JSON.stringify(rootState.ui.map_zone_google.geoJsonActif) + '&query_filter=' + JSON.stringify(state.menu_parcelles_filtres))

		axios.get('api=inex/calques/parcelles'+vars, { cancelToken: state.parcelles_geojson_axiostoken.token })
			.then(function (response) {
				commit('UI_REMOVE_LOADING_LAYER')
				commit('PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN', null)
				response.data.properties = {
					'style': state.parcelles_geojson_default_style
				}
				commit('PARCELLE_PARCELLES_GEOJSON', response.data)
				commit('PARCELLE_PARCELLES_AREA_TOO_BIG', false)
				
			}).catch(function (error) {
				commit('UI_REMOVE_LOADING_LAYER')
				if(error.response?.status == 413){
					commit('PARCELLE_PARCELLES_GEOJSON', null)
					commit('PARCELLE_PARCELLES_AREA_TOO_BIG', true)
				}
				else if(error.response?.status == 422){
					commit('PARCELLE_PARCELLES_GEOJSON', null)
				}
			}).finally(function(){
				commit('PARCELLE_SEARCH_REMOVE_LOADING');
			})
	},
	setParcellesOpacity({ commit, state, rootState }, opacity) {
		commit('PARCELLE_PARCELLES_GEOJSON_OPACITY', null)
		setTimeout(function(){
			commit('PARCELLE_PARCELLES_GEOJSON_OPACITY', opacity)
		}, 1)
	},
	setParcellesListOrder({ commit }, parcelles_list_order) {
		if(state.parcelles_list_order != parcelles_list_order){
			commit('PARCELLE_PARCELLES_LIST_ORDER', parcelles_list_order)
			commit('PARCELLE_PARCELLES_LIST_ORDER_DIRECTION', 'asc')
		}else{
			commit('PARCELLE_PARCELLES_LIST_ORDER_DIRECTION', state.parcelles_list_order_direction == 'asc' ? 'desc' : 'asc')
		}
	},

	loadParcelLists({ commit, state, rootState }) {
		axios.get('api=inex/parcelles/list').then(response => {
			commit('PARCELLE_PARCELLES_LISTS', response.data)
		})
	},

	setParcelleOnFormat({ commit, state, getters }, params) {
		let complement_updated = getters.getParcelleContentModifiedByFormat(params.format, params.content)
		let parcelle_on = _.cloneDeep(state.parcelle_on)
		parcelle_on.complements = complement_updated

		//AS--> Cas spécifique du champ "status" qui doit être géré aussi dans la fiche générale
		if(params.format == 'status') parcelle_on.general_info.Status = params.content.value

		commit('PARCELLE_PARCELLE_ON', parcelle_on)
	},

	setParcelleOnDetailledFormat({ commit, state, getters }, params) {
		let complement_updated = getters.getParcelleDetailledContentModifiedByFormat(params.format, params.content)
		let parcelle_on_detailled = _.cloneDeep(state.parcelle_on_detailled)
		parcelle_on_detailled.complements = complement_updated

		//AS--> Cas spécifique du champ "status" qui doit être géré aussi dans la fiche générale
		if(params.format == 'status') parcelle_on_detailled.general_info.Status = params.content.value

		commit('PARCELLE_PARCELLE_ON_DETAILLED', parcelle_on_detailled)
	},

	addParcelToLists({ commit, state, getters, dispatch }, lists) {
		// Ajoute la parcelle dans les listes chez iNex
		lists.forEach(list => {
			axios.put('api=inex/parcelles/list/'+list.list_id, {
				add_parcelle_ids: [state.parcelle_on_id]
			}).finally(function(){
				dispatch('loadParcelLists')
			})
		})

		// Recherche les parcel_lists dans les données détaillées
		if(state.parcelle_on_detailled){
			let parcelle_lists_content = getters.getParcelleDetailledContentByFormat('parcel_lists')
			parcelle_lists_content.value = [...parcelle_lists_content.value, ...lists]
			dispatch('setParcelleOnDetailledFormat', {format: 'parcel_lists', content: parcelle_lists_content})
		}else{
			let parcelle_lists_content = getters.getParcelleContentByFormat('parcel_lists')
			parcelle_lists_content.value = [...parcelle_lists_content.value, ...lists]
			dispatch('setParcelleOnFormat', {format: 'parcel_lists', content: parcelle_lists_content})
		}

	},

	removeParcelFromList({ commit, state, getters, dispatch }, list) {
		axios.put('api=inex/parcelles/list/'+list.list_id, {
			remove_parcelle_ids: [state.parcelle_on_id]
		}).finally(function(){
			dispatch('loadParcelLists')
		})

		// Recherche les parcel_lists dans les données détaillées
		if(state.parcelle_on_detailled){
			let parcelle_lists_content = getters.getParcelleDetailledContentByFormat('parcel_lists')
			parcelle_lists_content.value = parcelle_lists_content.value.filter(l => l.list_id != list.list_id)
			dispatch('setParcelleOnDetailledFormat', {format: 'parcel_lists', content: parcelle_lists_content})
		}else{
			let parcelle_lists_content = getters.getParcelleContentByFormat('parcel_lists')
			parcelle_lists_content.value = parcelle_lists_content.value.filter(l => l.list_id != list.list_id)
			dispatch('setParcelleOnFormat', {format: 'parcel_lists', content: parcelle_lists_content})
		}

		//Si list.list_id correspond à la liste chargée dans parcelles_list, on l'update
		if(state.parcelles_list.list_id == list.list_id){
			setTimeout(function(){
				dispatch('loadParcelList', list.list_id)
			}, 50)
		}
	},

	deleteParcelleListe({ commit, state, rootState, dispatch }, parcelle_liste) {
		// AS--> Si parcelle_liste est un objet, on récupère son id
		let list_id = parcelle_liste.list_id ?? parcelle_liste

		axios.delete('api=inex/parcelles/list/'+list_id)
			.finally(function () {
				dispatch('loadParcelLists')
			})
	},

	loadParcelList({ commit, state, rootState }, list_id) {
		// Remet le système sur la page qui affiche la liste de parcelles
		commit('UI_SET_CONTENT', { content: 'result' })
		commit('UI_SET_PAGE', 'results')

		let vars = '?query_filter=' + JSON.stringify(state.menu_parcelles_filtres_liste)

		axios.get('api=inex/parcelles/list/'+list_id+vars).then(response => {
			response.data.features = response.data.parcels
			response.data.type     = "FeatureCollection"
			response.data.properties = {
				'style': state.parcelles_geojson_default_style
			}
			response.data.properties.style.strokeOpacity =	0.2
			commit('PARCELLE_PARCELLE_LIST', response.data)
		})
	},

	resetParcellesListe({ commit }) {
		commit('PARCELLE_PARCELLE_LIST', null)
	},

	//AS--> Modifie la valeur du field dans la liste des parcelles, si la parcelle est trouvée
	//AS--> Modifie la valeur du field dans la fiche parcelle détaillée si celle qui est ouverte correspond à l'identifiant et si "format" est précisé. Attention, ne peut pas fonctionner sur des formats génériques comme "textarea"
	//AS--> Modifie la valeur du field dans la base de données iNex
	updateFieldParcelle({ commit, state, dispatch, getters }, {identifiant, field, value, format}) {

		
		//AS--> Modifie le field dans la liste des parcelles, si la parcelle est trouvée
		let parcelles = _.cloneDeep(state.parcelles_geojson)
		if(parcelles?.features?.length){
			let parcelle = _.find(parcelles.features, function(f) { return f.properties.idu == identifiant })
			if(parcelle){
				let field_to_update = field

				//AS--> Cas spécifique du champ "comment" qui doit être public_comment
				if(field == 'comment') field_to_update = 'public_comment'

				parcelle.properties[field_to_update] = value
				commit('PARCELLE_PARCELLES_GEOJSON', parcelles)
			}
		}

		//Fait la même chose pour parcelles_list
		let parcelles_list = _.cloneDeep(state.parcelles_list)
		if(parcelles_list?.features?.length){
			let parcelle = _.find(parcelles_list.features, function(f) { return f.properties.idu == identifiant })
			if(parcelle){
				let field_to_update = field
				
				//AS--> Cas spécifique du champ "comment" qui doit être public_comment
				if(field == 'comment') field_to_update = 'public_comment'
				
				parcelle.properties[field_to_update] = value
				commit('PARCELLE_PARCELLE_LIST', parcelles_list)
			}
		}

		//AS--> Modifie le field dans la fiche parcelle détaillée si celle qui est ouverte correspond à l'identifiant
		if(format && state.parcelle_on_id == identifiant){
			if(state.parcelle_on_detailled){
				let status_content = getters.getParcelleDetailledContentByFormat(format)
				status_content.value = value
				dispatch('setParcelleOnDetailledFormat', {format: format, content: status_content})
			}else{
				let status_content = getters.getParcelleContentByFormat(format)
				status_content.value = value
				dispatch('setParcelleOnFormat', {format: format, content: status_content})
			}
		}

		//AS--> Modifie le field dans la base de données iNex
		let values = {}
		values[field] = value
		axios.put('api=inex/parcelles/'+identifiant, values)
	},

}

// mutations
const mutations = {
	[types.PARCELLE_FEATURE_OVER](state, feature) {
		state.feature_over = feature;
	},
	[types.PARCELLE_PARCELLE_ON_ID](state, parcelle_id) {
		console.time('PARCELLE_PARCELLE_ON_ID' + ' - ' + parcelle_id);
		state.parcelle_on_id = parcelle_id;
		console.timeEnd('PARCELLE_PARCELLE_ON_ID' + ' - ' + parcelle_id);
	},
	[types.PARCELLE_PARCELLE_ON_ID_OLD](state, parcelle_id) {
		console.time('PARCELLE_PARCELLE_ON_ID_OLD');
		state.parcelle_on_id_old = parcelle_id;
		console.timeEnd('PARCELLE_PARCELLE_ON_ID_OLD');
	},
	[types.PARCELLE_PARCELLE_ON](state, parcelle) {
		console.time('PARCELLE_PARCELLE_ON');
		state.parcelle_on = parcelle;
		console.timeEnd('PARCELLE_PARCELLE_ON');
	},
	[types.PARCELLE_PARCELLE_ON_DETAILLED](state, parcelle) {
		console.time('PARCELLE_PARCELLE_ON_DETAILLED');
		state.parcelle_on_detailled = parcelle;
		console.timeEnd('PARCELLE_PARCELLE_ON_DETAILLED');
	},
	[types.PARCELLE_AFFICHAGE](state, affichage) {
		console.time('PARCELLE_AFFICHAGE');
		state.affichage = affichage;
		console.timeEnd('PARCELLE_AFFICHAGE');
	},
	[types.PARCELLE_SEARCH_ADD_LOADING](state) {
		console.time('PARCELLE_SEARCH_ADD_LOADING');
		state.loading_search = true;
		console.timeEnd('PARCELLE_SEARCH_ADD_LOADING');
	},
	[types.PARCELLE_SEARCH_REMOVE_LOADING](state) {
		console.time('PARCELLE_SEARCH_REMOVE_LOADING');
		state.loading_search = false
		console.timeEnd('PARCELLE_SEARCH_REMOVE_LOADING');
	},
	[types.PARCELLE_ADD_LOADING](state) {
		console.time('PARCELLE_ADD_LOADING');
		state.loading = state.loading +1;
		console.timeEnd('PARCELLE_ADD_LOADING');
	},
	[types.PARCELLE_REMOVE_LOADING](state) {
		console.time('PARCELLE_REMOVE_LOADING');
		let loading = state.loading -1;
		if(loading < 0) loading = 0;
		state.loading = loading
		console.timeEnd('PARCELLE_REMOVE_LOADING');
	},
	[types.PARCELLE_PARCELLES_FAVORIS](state, favoris) {
		console.time('PARCELLE_PARCELLES_FAVORIS');
		state.favoris = favoris
		console.timeEnd('PARCELLE_PARCELLES_FAVORIS');
	},
	[types.PARCELLE_MENU_PARCELLES_FILTRES](state, menu_parcelles_filtres) {
		console.time('PARCELLE_MENU_PARCELLES_FILTRES');
		state.menu_parcelles_filtres = menu_parcelles_filtres
		console.timeEnd('PARCELLE_MENU_PARCELLES_FILTRES');
	},
	[types.PARCELLE_MENU_PARCELLES_FILTRES_LISTE](state, menu_parcelles_filtres_liste) {
		console.time('PARCELLE_MENU_PARCELLES_FILTRES_LISTE');
		state.menu_parcelles_filtres_liste = menu_parcelles_filtres_liste
		console.timeEnd('PARCELLE_MENU_PARCELLES_FILTRES_LISTE');
	},
	[types.PARCELLE_MENU_PARCELLES_AFFICHE](state, menu_parcelles_affiche) {
		console.time('PARCELLE_MENU_PARCELLES_AFFICHE');
		state.menu_parcelles_affiche = menu_parcelles_affiche
		console.timeEnd('PARCELLE_MENU_PARCELLES_AFFICHE');
	},
	[types.PARCELLE_PARCELLES_GEOJSON](state, parcelles_geojson) {
		console.time('PARCELLE_PARCELLES_GEOJSON');
		state.parcelles_geojson = parcelles_geojson
		console.timeEnd('PARCELLE_PARCELLES_GEOJSON');
	},
	[types.PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN](state, parcelles_geojson_axiostoken) {
		console.time('PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN');
		state.parcelles_geojson_axiostoken = parcelles_geojson_axiostoken
		console.timeEnd('PARCELLE_PARCELLES_GEOJSON_AXIOS_TOKEN');
	},
	[types.PARCELLE_PARCELLES_GEOJSON_OPACITY](state, parcelles_geojson_opacity) {
		console.time('PARCELLE_PARCELLES_GEOJSON_OPACITY');
		state.parcelles_geojson_opacity = parcelles_geojson_opacity
		console.timeEnd('PARCELLE_PARCELLES_GEOJSON_OPACITY');
	},
	[types.PARCELLE_PARCELLES_AREA_LIMIT](state, parcelles_area_limit) {
		console.time('PARCELLE_PARCELLES_AREA_LIMIT');
		state.parcelles_area_limit = parcelles_area_limit
		console.timeEnd('PARCELLE_PARCELLES_AREA_LIMIT');
	},
	[types.PARCELLE_PARCELLES_AREA_TOO_BIG](state, isTooBig) {
		console.time('PARCELLE_PARCELLES_AREA_TOO_BIG');
		state.parcelles_areaTooBig = isTooBig
		console.timeEnd('PARCELLE_PARCELLES_AREA_TOO_BIG');
	},
	[types.PARCELLE_PARCELLES_LIST_ORDER](state, parcelles_list_order) {
		console.time('PARCELLE_PARCELLES_LIST_ORDER');
		state.parcelles_list_order = parcelles_list_order
		console.timeEnd('PARCELLE_PARCELLES_LIST_ORDER');
	},
	[types.PARCELLE_PARCELLES_LIST_ORDER_DIRECTION](state, parcelles_list_order_direction) {
		console.time('PARCELLE_PARCELLES_LIST_ORDER_DIRECTION');
		state.parcelles_list_order_direction = parcelles_list_order_direction
		console.timeEnd('PARCELLE_PARCELLES_LIST_ORDER_DIRECTION');
	},
	[types.PARCELLE_PARCELLES_LISTS](state, parcelles_lists) {
		console.time('PARCELLE_PARCELLES_LISTS');
		state.parcelles_lists = parcelles_lists
		console.timeEnd('PARCELLE_PARCELLES_LISTS');
	},
	[types.PARCELLE_PARCELLE_LIST](state, parcelles_list) {
		console.time('PARCELLE_PARCELLE_LIST');
		state.parcelles_list = parcelles_list
		console.timeEnd('PARCELLE_PARCELLE_LIST');
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}