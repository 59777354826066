<template>
  <span class="contenteditable_container" :class="getClasses">
    <span ref="champ" class="form_contenteditable" :contenteditable="editable" :placeholder="placeholder" @input="input" @click="activate" v-html="innerHTML" />
    <span v-if="!hasContent && placeholder" class="placeholder" contentEditable="false" @click="placeholder_activate">{{ placeholder }}</span>
  </span>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import HtmlTricks from '../mixins/HtmlTricks.js'

export default {
  mixins: [HtmlTricks],
  name: "ContentEditable",
  data() {
    return {
      unique_id: _.uniqueId('contenteditable_'),
      innerHTML: null,
      editable: false
    };
  },
  props: {
    value: String,
    data: {
      type: [String, Number, Boolean, Array, Object],
      default: null
    },
    edition: {
      type: Boolean,
      default: false
    },
    always_editable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    focusOnEdition: {
      type: Boolean,
      default: false
    },
    spanClass: {
      type: String,
      default: ''
    },
    top0: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    activable_on_click: {
      type: Boolean,
      default: false
    }
  },
  watch:{
    edition: function(after, before){
      let self = this
      if(after == false) this.$refs.champ.textContent = this.innerHTML
      if(after == true && this.focusOnEdition){
        setTimeout(function(){
          self.$refs.champ.focus()
        }, 50)
      }
    },
    always_editable: function(after){
      if(after) this.editable = true
    }
  },
  created() {
    if(this.data){
      this.innerHTML = this.data
      if(typeof this.innerHTML == 'string') this.innerHTML = this.innerHTML.trim()
      this.$emit('input', this.innerHTML)
    }else{
      this.innerHTML = this.value
    }
    // Remplace les retours à la ligne réels par <br>
    if(this.innerHTML && typeof this.innerHTML == 'string') this.innerHTML = this.innerHTML?.replace(/\n/g, '<br>')
    this.editable = this.always_editable ? true : _.cloneDeep(this.edition)
  },
  mounted() {
    document.querySelector("#app_content")?.addEventListener('click', this.deactivate)
  },
  beforeDestroy() {
    document.querySelector("#app_content")?.removeEventListener('click', this.deactivate)  
  },
  methods: {
    _kebabCase(field) {
      return kebabCase(field);
    },
    input(event){
      let value = event.target.innerText
      if(typeof value == 'string') value = value.trim()
      this.$emit('input', value)
    },
    placeholder_activate(){
      if(this.edition) this.focus_at_end_contenteditable(this.$refs.champ)
    },
    activate(){
      if(this.activable_on_click){
        if(!this.editable && !this.always_editable){
          this.editable = true
          setTimeout(function(){
            this.focus_at_end_contenteditable(this.$refs.champ)
          }, 10)
        }
      }
    },
    deactivate(event){
      if (!this.always_editable && event.target.closest('.' + this.uniqueClass) == null) this.editable = false
    }
  },
  computed: {
    uniqueClass(){
      return 'contenteditable_' + this.unique_id
    },
    getClasses(){
      let classes = [this.uniqueClass]
      if(this.always_editable) classes.push('always_editable')
      if(this.editable) classes.push('field_edition')
      if(this.hasContent) classes.push('has-content')
      if(this.top0) classes.push('top0')
      if(this.inline) classes.push('inline')
      return classes
    },
    hasContent(){
      return this.value && this.value?.trim().length > 0
    },
  },
  components: {},
};
</script>
<style scoped lang="scss">
// span[contenteditable][placeholder]:empty:before {
//   content: attr(placeholder);
//   color: gray;
//   background-color: transparent;
//   pointer-events: none;
// }
</style>
