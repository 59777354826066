export default {
    data(){
        return {
            show: true
        }
    },
	props: {
		title: {
			type: String,
			required: true
		},
        show_default: {
            type: Boolean,
            default: true
        }
	},
    mounted(){
        this.show = this.show_default;
    },
    methods: {
        toggle(){
            this.show = !this.show;
        }
    }
}