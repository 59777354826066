import Show_Component from '../show_component.vue'
import SectionSwitch from '@/common/ui/section_switch.vue'

export default {
	name: 'Onglets',
	data(){
		return {
			composant_onglets: null,
			composant_section_switch: null
		}
	},
	mounted(){
		this.loadComponents()
	},
	methods: {
		async loadComponents(){
            const component = await import('@/common/ui/onglets.vue');
            this.composant_onglets = component.default;

            const component_section_switch = await import('@/common/ui/section_switch.vue');
            this.composant_section_switch = component_section_switch.default;

        }
	},
	components: {
		Show_Component,
		SectionSwitch
	},
}
