import Autocomplete from '../../../common/forms/Autocomplete'
import axios from 'axios'

export default {
	name: 'parcelles_proprietaires',
    data(){
        return {
            localSearch: '',
            options: [],
            optionsRewritten: [],
            show: false
        }
    },
	props: {
        value: {
            type: String,
            default: null
        },
        params: {
            type: Object,
            default: {}
        }
	},
    methods: {
        getOptions(newSearch){
            let self = this
            this.localSearch = newSearch;
            //AS--> Si newSearch ne fait pas 9 caractères, on ne fait pas de requête
            if(newSearch.length !== 9){
                this.options = 'trop_court'
                this.rewriteOptions()
                return
            }

            //AS--> On récupère les options depuis l'API
            this.options = 'loading'
            this.rewriteOptions()
            axios.get('api=inex/parcelles/search?siren='+newSearch).then(response => {
                if(response.data.length === 0){
                    self.options = 'aucun'
                }else{
                    self.options = response.data
                }
                this.rewriteOptions()
            })
        },
        updateChoice(choice) {
            this.$emit('input', choice?.value ?? null);
        },
        rewriteOptions(){
            if(this.options === 'loading'){
                this.optionsRewritten = [
                    {
                        label: this.$t('loading_inex_text'),
                        value: null,
                        uniqId: Math.random()
                    }
                ]
                return
            }
            if(this.options === 'aucun'){
                this.optionsRewritten = [
                    {
                        label: this.$t('filter_parcelles_proprietaires_no_results'),
                        value: null,
                        uniqId: Math.random()
                    }
                ]
                return
            }
            if(this.options === 'trop_court'){
                this.optionsRewritten = [
                    {
                        label: this.$t("filter_parcelles_proprietaires_pas_9_chiffres"),
                        value: null,
                        uniqId: Math.random()
                    }
                ]
                return
            }
            this.optionsRewritten = this.options.map(option => {
                return {
                    //AS--> Transforme le siren en 3 groupes de 3 chiffres
                    label: '<b>'+option.siren.replace(/(\d{3})(?=\d)/g, '$1 ')+'</b>',
                    picto: 'Building',
                    value: option.siren
                }
            })
        },
        switchShow(){
            this.show = !this.show
            if(!this.show) this.$emit('input', null)
        }
    },
	components: {
		Autocomplete
	}
}