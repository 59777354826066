<template>
  <div>
    <Onglets :onglets="onglets_menu" v-model="choice" :offset="0" />
  </div>
</template>
  
<script>
import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import Onglets from '../../common/ui/onglets.vue'
export default {
  name: "Switcher",
  data() {
    return {
      active: false,
      choice: null
    };
  },
  props: {
    value: null,
    options: Object,
    datas: Object,
    errors: Object,
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    field: String,
    label: String,
    params: {
      type: Object,
      default: {}
    },
  },
  created() {
    this.init()
  },
  mounted() {},
  watch: {
    //AS--> Réinitialisation des filtres par l'extérieur. Si la value passe à undefined, on ferme le filtre
    value: function(after, before){
      if(typeof after == 'undefined'){
        this.init()
      }
    },
    choice: function(after, before){
      let new_value = this.getOptions[after].value
      this.updateValue(new_value)
    }
  },
  methods: {

    init(){
      this.updateValue(this.getOptions[0].value)
    },

    //AS--> Transforme le nom du champ en kababcase pour en faire une class
    _kebabCase(field) {
      return kebabCase(field);
    },

    //AS--> Mais à jour la value de référence en la passant au parent. La value interne sera mise à jour automatiquement
    updateValue(val){
      this.$emit('input', val)
    },

    //AS--> Renvoie le tooltip de l'option
    getTooltip(option){
      if(option.tooltip) return option.tooltip
      return false
    },

    
  },
  computed: {
    getErrors() {
      return get(this.errors, this.field);
    },

    //AS--> Renvoie le field en fonction des différentes options de paramétrage
    getField(){
      if(this.params.code) return this.params.code
      return this.field
    },

    //AS--> Renvoie le label en fonction des différentes options de paramétrage
    getLabel(){
      if(this.params.label) return this.params.label
      if(this.params.name) return this.params.name
      return this.label
    },

    //AS--> Renvoie la liste des options en fonction des différentes options de paramétrage
    getOptions(){
      let options = []
      if(this.params.options){
        options = this.params.options
      }else{
        options = this.options
      }
      return options
    },

    onglets_menu(){
      let options = this.params.options
      if(!options) options = this.options
      return options.map((option, option_i) => ({
        code: option_i,
        label: option.name,
        tooltip: this.getTooltip(option)
      }))
    }

  },
  components: {
    Onglets
  },
};
</script>