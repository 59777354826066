import Show_Component from '../show_component.vue'

export default {
	name: 'Content_Structure',
	data(){
		return {
            composant_zone: null,
			composant_textarea: null,
			composant_html: null
		}
	},
	mounted(){
		this.loadComponents()
	},
	methods: {
		async loadComponents(){
            const zone = await import('@/common/content_structure/zone.vue');
            this.composant_zone = zone.default;

            const textarea = await import('@/common/content_structure/textarea.vue');
            this.composant_textarea = textarea.default;

			const html = await import('@/common/content_structure/html.vue');
            this.composant_html = html.default;
        }
	},
	components: {
		Show_Component
	},
}
