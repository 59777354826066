var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Show_Component',{attrs:{"path":"common/ui/onglets","currentComponent":_vm.composant_onglets,"params":{
			vmodel: 'premier',
			onglets: [
				{code: 'premier', label: 'Premier'},
				{code: 'deuxieme', label: 'Deuxième'}
			]
		}}}),_c('Show_Component',{attrs:{"path":"common/ui/onglets","currentComponent":_vm.composant_onglets,"params":{
			vmodel: 'deuxieme',
			offset: 30,
			onglets: [
				{code: 'premier', label: 'Premier', tooltip: 'test avec un peu plus de contenu pour vérifier que tout fonctionne bien'},
				{code: 'deuxieme', label: 'Deuxième', tooltip: 'test'}
			]
		}}}),_c('Show_Component',{attrs:{"path":"common/ui/onglets","currentComponent":_vm.composant_onglets,"params":{
			class: 'w100',
			vmodel: 'premier',
			offset: 20,
			onglets: [
				{code: 'premier', label: 'Premier'},
				{code: 'deuxieme', label: 'Deuxième'}
			]
		},"notes":"La classe w100 permet de prendre toute la largeur de la div et de centrer les onglets"}}),_c('Show_Component',{attrs:{"path":"common/ui/section_switch","currentComponent":_vm.composant_section_switch,"params":{
			show_default: true,
			title: 'Titre de section'
		},"notes":"Le contenu de la section est passé en tant que slot\n\t\tshow_default: par défaut à true, permet de paramétrer l'ouverture ou non de la section au départ\n\t\t","slot_content":"<p>Contenu de la section</p>"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }