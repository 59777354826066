import Filter_ui from './filter_ui.vue'

import Page_onglets from './pages/onglets.vue'
import Page_fields from './pages/fields.vue'
import Page_content_structure from './pages/content_structure.vue'
import Page_menus from './pages/menus.vue'

export default {
	methods: {
	},
	components: {
		Filter_ui,
		Page_onglets,
		Page_fields,
		Page_content_structure,
		Page_menus
	}
}