import vueCustomScrollbar from 'vue-custom-scrollbar'
import Debounce from 'lodash/debounce'
import FiltreHeader from './filtres/_filtre_header.vue';
import _ from 'lodash'
import FiltreStatuses from './filtres/filtre_statuses.vue'

export default {
	data() {
		return {
		}
	},
	methods: {
		close_filter() {
			this.$store.commit('UI_SET_SHOW_FILTERS', { filters: false });
		},
		reset_filters() {
			this.$store.dispatch('resetFilterStatuts');
		},
		selectAll(){
			this.$store.dispatch('selectAllFilterStatut');
		}
	},
	computed: {
		getStatusCheckAll(){
			if(this.$store.state.filters.filters.statuses?.length && (this.$store.getters.getStatuses('companies').length + 1) == this.$store.state.filters.filters.statuses.length){
				return 'on'
			}
			return ''
		}
	},
	watch: {},
	components: {
		vueCustomScrollbar,
		FiltreHeader,
		FiltreStatuses
	}
}