export default {
	props: ['index'],
	methods: {
		switch_ui(event){
			if (this.$store.state.ui.content == 'ui') {
				this.$store.dispatch('setCompany', null)
				this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
				this.$store.commit('UI_SET_CONTENT', {content: 'result'});
			} else {
				this.$store.commit('UI_SET_SHOW_FILTERS', {filters: false});
				this.$store.commit('UI_SET_CONTENT', {content: 'ui'});
			}
		},
	}
}