import _ from 'lodash'

export default {
	data() {
		return {
			settings: {
				// maxScrollbarLength: 60
			}
		}
	},
	props: ['params','menu_index'],
	watch: {
		"$store.getters.getUiFiltres"(after, before){
			if(before[this.menu_index]?.reinit_index != after[this.menu_index]?.reinit_index) this.reset_filters()
			if(before[this.menu_index]?.selectAll_index != after[this.menu_index]?.selectAll_index) this.selectAll()
		}
	},
	methods: {
		reset_filters() {
			this.$store.dispatch('resetFilterStatuts');
		},
		selectAll(){
			this.$store.dispatch('selectAllFilterStatut');
		},
		openModalStatuses(){
			this.$store.dispatch('open_modal', 'edition_statuses')
			this.$store.commit('UI_SET_STATUS_TARGET_EDITION', 'companies')
		}
	},
	computed:{
		getStatuses() {
			let statuses = _.cloneDeep(this.$store.getters.getStatuses('companies'))
			if(statuses && statuses.length) {
				statuses.push({
					"slug": null,
					"name": this.$t('statuses_no_status'),
					"color": "#6ed408",
					"order": 100000
				})
			}
			return statuses
		},
	},
	components: {}
}