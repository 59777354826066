import Show_Component from '../show_component.vue'
import Menu_sous_menu from '@/common/ui/menu_sous-menu.vue'


export default {
  name: 'Menus',
  data(){
    return {
      composant_menu_sous_menu: null
    }
  },
  mounted(){
    this.loadComponents()
  },
  methods: {
    async loadComponents(){
      const menu_sous_menu = await import('@/common/ui/menu_sous-menu.vue')
      this.composant_menu_sous_menu = menu_sous_menu.default
    },
    affiche(content){
      this.$dialog.alert(content)
    }
  },
  components: {
    Show_Component,
    Menu_sous_menu
  }
}
