import _ from 'lodash'
import StatusesSelector from '../ui/statuses_selector.vue'

export default {
	data() {
		return {
			
		}
	},
	props: {
		element: Object
	},
	mounted(){
		
	},
	methods:{
		statusChange(status){
			this.element.value = status
		}
	},
	computed:{
		cible(){
			return this.target == 'companies' ? {
				identifiant    : this.$store.state.company.company.id,
				current_status : this.element.value
			} : {
				identifiant    : this.$store.state.parcelle.parcelle_on_id,
				current_status : this.element.value
			}
		},
		target(){
			return this.$store.state.parcelle.parcelle_on_id ? 'parcels' : 'companies'
		}
	},
	components:{
		StatusesSelector
	}
}