var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"onglets_contenu"},[_c('div',{staticClass:"onglet"},[_c('Show_Component',{attrs:{"path":"common/content_structure/zone","currentComponent":_vm.composant_zone,"params":{
                    element: {
                        name   : 'Zone',
                        point  : ['rgb(110 169 255)', 'rgb(58 105 182)'],
                        columns: 2,
                        content: [
                            {
                                name  : 'Sous-composant 1',
                                format: 'text',
                                value : '... affichage sous-composant 1 ...'
                            },
                            {
                                name  : 'Sous-composant 2',
                                format: 'text',
                                value : '... affichage sous-composant 2 ...'
                            },
                            {
                                name  : 'Sous-composant 3',
                                format: 'text',
                                value : '... affichage sous-composant 3 ...'
                            }
                        ]
                    }
                },"notes":"\n                    columns: 1, 2 ou 3 en fonction de la répartition en colonnes des sous-composants\n                    content: tableau des sous-composants\n                    "}}),_c('div',{staticClass:"zone"},[_c('Show_Component',{attrs:{"path":"common/content_structure/textarea","currentComponent":_vm.composant_textarea,"params":{
                        element: {
                            format         : 'textarea',
                            always_editable: true,
                            name           : 'Textarea',
                            value          : 'Ceci est du texte éditable par le client',
                            field          : 'comment',
                            placeholder    : 'Votre contenu personnalisé ici ...'
                        }
                    },"notes":"always_editable: true pour garder active visuellement la zone éditable. Sinon, la zone ne s'active que lorsqu'on clique dedans\n                    field: le field dans la base de données iNex qui sera modifié\n                    "}})],1),_c('div',{staticClass:"zone"},[_c('Show_Component',{attrs:{"path":"common/content_structure/html","currentComponent":_vm.composant_html,"params":{
                        element: {
                            name: 'HTML',
                            point: ['rgb(110 169 255)', 'rgb(58 105 182)'],
                            tooltip: 'Ceci est un tooltip',
                            value: '<p>Petit texte <u>d\'introduction</u> pour démontrer la <b>possibilité</b> d\'afficher du HTML. Puis affichage d\'une liste :</p><ul><li>Siren : 379 041 395</li><li>Nom : Framatome</li><li>Catégorie : Personnes morales non remarquables</li><li>Forme juridique abrégée : SAS</li></ul><p>Et une phrase de conclusion.</p><p class=\'mt-8\'>Exemple avec margin-top forcé</p>',
                            mention: 'mention : Koumoul'
                        }
                    },"notes":"\n                        tooltip: affichage d'un tooltip dans le titre du composant\n                        mention: affichage d'une mention en dessous du composant\n                        value: affichage d\\'un contenu full HTML avec la possibilité d'utiliser des class comme celles de margin et padding (mt-2, pb-4, ...)\n                    "}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }