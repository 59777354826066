import axios from '../common/axios.js'
import Debounce from 'lodash/debounce'
import _ from 'lodash'

import SearchPage from './search_page.vue';
import ResultsPage from './results_page.vue';
import Modal from './modal.vue';
import ModalContent from './modal_content.vue';
import Loading from './loading.vue';

import MenuMobile from './menu_mobile.vue'

import FilterParams from './filter_params.vue'
import FilterActors from './filter_actors.vue'
import FilterWast from './filter_wast.vue'
import FilterSector from './filter_sector.vue'
import FilterCharacteristics from './filter_characteristics.vue'
import FilterVariables from './filter_variables.vue'
import FilterParcelles from './filter_parcelles.vue'

import Variables from './_variables.vue'

import CentreAide from './centre-aide/base.vue'

import PdfComputing from './modals/pdf_computing.vue';

export default {
	data(){
		return {
			clear_timeout_modal_note: null
		}
	},
	created(){
		let self = this
		self.$store.dispatch('uiSetDevice')
		window.addEventListener('resize', Debounce(this.setDevice, 250));

		if (typeof document.onfullscreenchange != "undefined") {
			document.onfullscreenchange = function ( event ) { 
				self.$store.commit('UI_SWITCH_FULL_SCREEN', document.fullscreen);
			};
		}else if(typeof document.onwebkitfullscreenchange != "undefined"){
			document.onwebkitfullscreenchange = function ( event ) { 
				self.$store.commit('UI_SWITCH_FULL_SCREEN', document.webkitIsFullScreen);
			};
		}

		//AS--> Set les _GET
		let uri = window.location.href.split('?');
		let gets = []
		if (uri[1] != undefined) {
			let _gets = uri[1].split('&');
			_.forEach(_gets, function(param){
				param = param.split('=');
				gets[param[0]] = param[1] != undefined ? param[1] : true;
			})
		}
		self.$store.commit('UI_SET_GET', gets);

		self.$store.dispatch('setInitApp', self.$parent.init_app)
			.then(function(response){

				//AS--> Ouvre la modale d'intro si nécessaire
				if(self.$store.getters.canIntro){
					self.$store.commit('UI_SET_MODAL', { modal: 'intro' });
				}

			})


		//AS--> Appel pour maintenir la session ouverte
		setInterval(function(){
			axios.get('/plugin_projet/plugin_projet_iannuaire/session')
		}, 600000) //AS--> toutes les 10min
	},
	methods:{
		setDevice(){
			this.$store.dispatch('uiSetDevice')
		}
	},
	components: {
		SearchPage,
		ResultsPage,
		Loading,
		Modal,
		ModalContent,
		MenuMobile,
		FilterParams,
		FilterActors,
		FilterWast,
		FilterSector,
		FilterCharacteristics,
		FilterVariables,
		FilterParcelles,
		Variables,
		CentreAide,
		PdfComputing,
		Favoris: () => import('./favoris.vue'),
    	Search: () => import('./search.vue'),
		Account: () => import('./account.vue'),
		ModalCompany: () => import('./modal_company.vue'),
		Graph: () => import('./graph.vue'),
		Donwload: () => import('./download.vue'),
		Parcelle: () => import('./parcelle/parcelle.vue'),
		ParcelleFavoris: () => import('./parcelle/parcelle_favoris.vue'),
		OutilItineraire: () => import('./actions/outils/itineraire.vue'),
		OutilIsoline: () => import('./actions/outils/isoline.vue'),
	}
}