import Show_Component from '../show_component.vue'

export default {
	name: 'Onglets',
	data(){
		return {
			composant_input: null,
			composant_statuts: null,
			composant_contenteditable: null,
		}
	},
	mounted(){
		this.loadComponents()
	},
	methods: {
		async loadComponents(){
            const input = await import('@/common/forms/Input.vue');
            this.composant_input = input.default;

            const statuses = await import('@/common/ui/statuses_selector.vue');
            this.composant_statuts = statuses.default;

            const editable = await import('@/common/forms/ContentEditable.vue');
            this.composant_contenteditable = editable.default;
        }
	},
	components: {
		Show_Component
	},
}
