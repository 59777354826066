import _ from 'lodash'
import Modale from '../../common/ui/modale.vue'
import draggable from 'vuedraggable'
import LoadingBtn from '../../common/ui/loading_btn.vue'
import axios from 'axios'

export default {
	data(){
		return {
			statuses: [],
			deleted_slugs: [],
			hasChanges: false,
			enregistrements: false,
			target: this.$store.state.ui.statuses_target_edition,
		}
	},
	created(){
		//AS--> Récupère les statuts
		this.statuses = _.cloneDeep(this.$store.state.ui.statuses[this.target])
		//AS--> Ordonne par status.order
		this.statuses = _.sortBy(this.statuses, 'order')
	},
	watch:{
		enregistrements(after, before){

			//AS--> Si on termine des processus de mise à jour, on ferme la fenêtre et on rafraichit la liste
			if(after == 0 && before != 0){
				this.$store.dispatch('loadStatuses', {target: this.target})
				this.$store.dispatch('open_modal', null)
			}
		}
	},
	methods:{
		changeName(status, name){

			//AS--> Change le name du statut
			status.name  = name

			//AS--> Pour la réactivité
			let statuses = _.cloneDeep(this.statuses)
			this.statuses = statuses

			//AS--> Indique qu'il y a une modification à enregistrer
			this.hasChanges = true
		},
		changeColor(status, color){

			//AS--> Change le color du statut
			status.color  = color

			//AS--> Pour la réactivité
			let statuses = _.cloneDeep(this.statuses)
			this.statuses = statuses

			//AS--> Indique qu'il y a une modification à enregistrer
			this.hasChanges = true
		},
		log(evt){
			this.hasChanges = true
		},
		addNew(){
			this.statuses.push({
				slug: null,
				name: '',
				color: '#3A7198'
			})
			this.hasChanges = true
		},
		suppr(status){
			this.$dialog.confirm(this.$t('statuses_configuration_delete_confirm'))
			.then(dialog => {
				//AS--> Mémorise le statut à supprimer
				if(status.slug) this.deleted_slugs.push(status.slug)

				//AS--> Trouve la référence du statut à supprimer
				let index = this.statuses.indexOf(status)

				//AS--> Supprime le statut
				let statuses = _.cloneDeep(this.statuses)
				statuses.splice(index, 1)
				this.statuses = statuses

				//AS--> Indique qu'il y a une modification à enregistrer
				this.hasChanges = true
			})
		},
		validate(){

			let self = this

			//AS--> Suppression des statuts
			if(this.deleted_slugs.length){
				this.deleted_slugs.forEach(slug=>{
					self.enregistrements++
					axios.delete('api=inex/views/{view_code}/'+this.target+'/statuses/'+slug)
					.finally(function(){
						self.enregistrements--
					})
				})
			}

			//AS--> Affecte les orders
			this.statuses.forEach((status, index)=>{
				status.order = index
			})

			//AS--> Vérifie les statuts restants
			this.statuses.forEach(status=>{
				if(status.slug){

					//AS--> Vérifie si le statut a changé
					let original = this.$store.state.ui.statuses[this.target].filter(s=>s.slug === status.slug)[0]
					if(original.name !== status.name || original.color !== status.color || original.order !== status.order){
						self.enregistrements++
						axios.put('api=inex/views/{view_code}/'+this.target+'/statuses/'+status.slug, status)
						.finally(function(){
							self.enregistrements--
						})
					}

				}else{
					self.enregistrements++
					status.slug = self.getUniqueSlug(status.name)
					axios.post('api=inex/views/{view_code}/'+this.target+'/statuses', status)
					.finally(function(){
						self.enregistrements--
					})
				}
			})
		},
		getUniqueSlug(name){
			let slug = _.kebabCase(name)
			let i = 1
			let unique = false
			while(!unique){
				if(this.statuses.filter(status=>status.slug === slug).length === 0){
					unique = true
				}else{
					slug = _.kebabCase(name+'_'+i)
					i++
				}
			}
			return slug
		}
	},
	computed:{
		allNameFilled(){
			return this.statuses.filter(status=>status.name === '').length === 0
		}
	},
	components:{
		Modale,
		draggable,
		LoadingBtn,
	}
}