import _ from 'lodash'

//AS--> Eléments génériques
import ElementZone from './zone.vue'
import ElementText from './text.vue'
import ElementHtml from './html.vue'
import ElementCalques from './calques.vue'
import ElementVoirPlus from './voir_plus.vue'
import ElementCheckboxes from './checkboxes.vue'
import ElementCheckboxesMulti from './checkboxes_multi.vue'
import ElementVariables from './variables.vue'
import ElementWysiwyg from './wysiwyg.vue'
import ElementContacts from './contacts.vue'
import ElementProducts from './products.vue'
import ElementStatus from './status.vue'
import ElementMultiChoicesWithChilds from './multi_choices_with_childs.vue'
import ElementTextarea from './textarea.vue'

//AS--> Eléments de parcelles
import ElementContactProprietaire from '../../components/parcelle/contact_proprietaire.vue'
import ElementCommentaire from '../../components/parcelle/commentaire.vue'
import ElementImages from '../../components/parcelle/images.vue'
import ElementParcelLists from '../../components/parcelle/parcel_lists.vue'

//AS--> Eléments fiche entreprise
import ElementEntrepriseCapacites from '../../components/fiche/fiche_info_capacites.vue'
import ElementEntrepriseChiffresCles from '../../components/fiche/fiche_info_chiffres_cles.vue'
import ElementEntrepriseCommentaire from '../../components/fiche/fiche_info_commentaire_sourcing.vue'
import ElementEntrepriseCompetences from '../../components/fiche/fiche_info_competences.vue'
import ElementEntrepriseContacts from '../../components/fiche/fiche_info_contacts.vue'
import ElementEntrepriseDescription from '../../components/fiche/fiche_info_description.vue'
import ElementEntrepriseFamillesPrincipaleSecondaires from '../../components/fiche/fiche_info_familles_principale_secondaires.vue'
import ElementEntrepriseInformations from '../../components/fiche/fiche_info_informations.vue'
import ElementEntrepriseMarches from '../../components/fiche/fiche_info_marches.vue'
import ElementEntrepriseMatieres from '../../components/fiche/fiche_info_matieres.vue'
import ElementEntrepriseSuiviActivites from '../../components/fiche/fiche_info_suivi_activites.vue'

export default {
	data() {
		return {
			
		}
	},
	props: {
		element: {
			type: Object,
			default: {},
			required: true
		},
		index: {
			type: Number|String,
			default: undefined
		},
		top: {
			type: Object,
			default: undefined
		}
	},
	created(){
		
	},
	methods: {
		
	},
	computed:{
		
	},
	components:{
		ElementZone,
		ElementVoirPlus,
		ElementCheckboxes,
		ElementCheckboxesMulti,
		ElementVariables,
		ElementWysiwyg,
		ElementContacts,
		ElementProducts,
		ElementStatus,
		ElementMultiChoicesWithChilds,
		ElementTextarea,
		ElementText,
		ElementHtml,
		ElementContactProprietaire,
		ElementCalques,
		ElementCommentaire,
		ElementImages,
		ElementParcelLists,
		ElementEntrepriseCapacites,
		ElementEntrepriseChiffresCles,
		ElementEntrepriseCommentaire,
		ElementEntrepriseCompetences,
		ElementEntrepriseContacts,
		ElementEntrepriseDescription,
		ElementEntrepriseFamillesPrincipaleSecondaires,
		ElementEntrepriseInformations,
		ElementEntrepriseMarches,
		ElementEntrepriseMatieres,
		ElementEntrepriseSuiviActivites,
	}
}