import { render, staticRenderFns } from "./parcel_lists_add_in.vue?vue&type=template&id=5b9cbc37&scoped=true"
import script from "./parcel_lists_add_in.js?vue&type=script&lang=js&external"
export * from "./parcel_lists_add_in.js?vue&type=script&lang=js&external"
import style0 from "./parcel_lists_add_in.vue?vue&type=style&index=0&id=5b9cbc37&prod&scoped=true&lang=scss"
import style1 from "./parcel_lists_add_in.vue?vue&type=style&index=1&id=5b9cbc37&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9cbc37",
  null
  
)

export default component.exports