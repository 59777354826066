export const ContentStructureUtils = {
    getContentByFormat: (contents,format) => {
		if(!contents) return null

		let contentToReturn = null
		contents.forEach(complement => {
			if(complement.format == format){
				contentToReturn = complement
			}else if(complement.content){
				let subContent = ContentStructureUtils.getContentByFormat(complement.content, format)
				if(subContent) contentToReturn = subContent
			}
		})
		return contentToReturn
	},

    /**
     * Retourne les compléments détaillés dont un format est modifié par le contenu passé en paramètre
     * @param {array} contents Compléments détaillés à modifier
     * @param {string} format Format du complément à modifier
     * @param {object} content Contenu à appliquer au complément
     * @returns {array}
     */
    getContentModifiedByFormat: (contents, format, content) => {
		if(!contents) return null

		contents.forEach((complement, index) => {
			if(complement.format == format){
                contents[index] = {...complement, ...content}
			}
			if(complement.content){
				complement.content = ContentStructureUtils.getContentModifiedByFormat(complement.content, format, content)
			}
		})
		return contents
	},
};