import { render, staticRenderFns } from "./parcelles_proprietaires.vue?vue&type=template&id=3ea7b5ba&scoped=true"
import script from "./parcelles_proprietaires.js?vue&type=script&lang=js&external"
export * from "./parcelles_proprietaires.js?vue&type=script&lang=js&external"
import style0 from "./parcelles_proprietaires.vue?vue&type=style&index=0&id=3ea7b5ba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea7b5ba",
  null
  
)

export default component.exports