import _ from 'lodash'
import axios from 'axios'
import MenuSousMenu from '../../common/ui/menu_sous-menu.vue'
import Content from '../../common/content_structure/_base.vue'

export default {
	data() {
		return {
		}
	},
	created(){
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector(".c-results-page")?.addEventListener('click', this.close_modal);
	},
	beforeDestroy: function() {
		document.querySelector(".c-results-page")?.removeEventListener('click', this.close_modal);
	},
	methods: {
		close_modal(event) {
			if (event.target.closest('#parcelle') == null && event.target.closest('#table_parcelles') == null && event.target.closest('#inex_map') == null && event.target.closest('#recherche_parcelle') == null && event.target.closest('.c-filters') == null && event.target.closest('.c-menu') == null) {
				this.$store.dispatch('setParcelleOnId', null);
			}
		},
		close(){
			this.$store.dispatch('setParcelleOnId', null);
		},
		affichageDetaille(){
			this.$store.dispatch('setParcelleAffichage', 'detaille');
		},
		impression(){
			let self = this

			let layer_opened = false
			if(this.$store.getters.getUiFiltres){
				this.$store.getters.getUiFiltres.forEach(function(menu, filter_index){
					if(!layer_opened && menu.filters && menu.filters.length){
						menu.filters.forEach(function(filter){
							if(filter.type && filter.type == 'layers'){
								layer_opened = true
								self.$store.commit('UI_SET_SHOW_FILTERS', {filters: true});
								self.$store.commit('UI_SET_FILTER_INDEX', {filter_index: filter_index+1});
							}
						})
					}
				})
			}else{
				self.$store.getters.getMenusColonneVerte.forEach(function(menu, filter_index){
					if(menu == 'parametres'){
						self.$store.commit('UI_SET_SHOW_FILTERS', {filters: true});
						self.$store.commit('UI_SET_FILTER_INDEX', {filter_index: filter_index+1});
					}
				})
			}

			//AS--> Met en place la class d'impression spéciale pour les parcelles
			this.$store.commit('UI_SET_CLASS_IMPRESSION', 'print-parcelle')
			setTimeout(function(){
				window.print()
				//AS--> Remet en place la class d'impression par défaut après l'impression
				setTimeout(function(){
					self.$store.commit('UI_SET_CLASS_IMPRESSION', 'print-default')
			    },500)
			},1500)
		},
		addFavoris(){
			this.$store.dispatch('addParcelleFavoris');
		},
		delFavoris(){
			this.$store.dispatch('delParcelleFavoris', this.$store.state.parcelle.parcelle_on_id);
		},
		telecharger(){
			let self = this
			let options = {
				headers: {
					'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				},
				responseType: 'blob'
			}
			axios.get('api=inex/parcelles/{parcelle_id}?information_type=all', options)
				.then(function (response) {
					let file_name = self.nom+'.xlsx'
					if (!window.navigator.msSaveOrOpenBlob){ 
						// BLOB NAVIGATOR 
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file_name);
						document.body.appendChild(link);
						link.click();
					}else{ 
						// BLOB FOR EXPLORER 11 
						const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), file_name);
					}
					
				}).catch(function (error) {

				})
		}
	},
	computed:{
		parcelle_infos(){
			if(this.$store.state.parcelle.affichage == 'synthetique'){
				return this.$store.state.parcelle.parcelle_on
			}else{
				return this.$store.state.parcelle.parcelle_on_detailled ? this.$store.state.parcelle.parcelle_on_detailled : this.$store.state.parcelle.parcelle_on
			}
		},
		nom(){
			let nom = [this.$t('parcelle_titre_parcelle')]
			if(this.parcelle_infos){
				nom.push(this.parcelle_infos.general_info.Section)
				nom.push(this.parcelle_infos.general_info['Numéro de parcelle'])

			}else if(this.$store.state.parcelle.parcelle_on_id){
				nom.push('...')
			}
			return nom.join(' ')
		},
		infos(){
			let infos = []
			if(this.parcelle_infos){
				if(this.parcelle_infos.general_info){
					if(this.parcelle_infos.general_info['Superficie']){
						infos.push(this.$t('parcelle_titre_superficie') + ' ' + this.parcelle_infos.general_info['Superficie'])
					}
					if(this.parcelle_infos.general_info['Nom de la commune']){
						infos.push(this.parcelle_infos.general_info['Nom de la commune'])
					}
				}

			}else if(this.$store.state.parcelle.parcelle_on_id){
				infos.push(' ')
			}
			return infos.join(' • ')
		},
		properties(){
			if(this.$store.state.parcelle.feature_on){
				let props = []
				this.$store.state.parcelle.feature_on.forEachProperty(function(value, key){
					props.push({key, value})
				})
				return props
			}
			return []
		},
		isInFavoris(){
			let self = this
			let isInFavoris = false
			if(this.$store.state.parcelle.favoris && this.$store.state.parcelle.favoris.length){
				self.$store.state.parcelle.favoris.forEach(function(favoris){
					let favoris_id = favoris.value ? favoris.value : favoris
					if(favoris_id == self.$store.state.parcelle.parcelle_on_id) isInFavoris = true;
				})
			}
			return isInFavoris
		},
		menu_telecharger(){
			return {
				menu: {
					picto: 'Download2',
					label: false
				},
				sousmenu: {
					list: [
						{
							picto: 'Xls',
							label: this.$t('parcelle_telecharger_xls'),
							action: this.telecharger,
							width: 18,
							height: 18
						},
						{
							picto: 'Image',
							label: this.$t('parcelle_telecharger_pdf'),
							action: this.impression
						}
					]
				}
			}
		}
	},
	components:{
		Content,
		MenuSousMenu
	}
}