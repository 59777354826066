var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('Show_Component',{attrs:{"path":"common/ui/menu_sous-menu","currentComponent":_vm.composant_menu_sous_menu,"params":{
          settings : {
              menu: {
                  picto: 'Setting',
                  viewBox: '0 0 48 48',
                  width: 16,
                  height: 16,
                  label: '',
                  arrow: false,
                  bg_color: '',
                  color: ''
              },
              sousmenu: {
                  class: '',
                  list: [
                      {
                          label: 'Programmer',
                          action: '',
                          picto: 'CalandarDays',
                          viewBox: '0 0 24 24',
                          width: 12,
                          height: 12,
                          picto_post: '',
                          viewBox_post: '0 0 24 24',
                          width_post: 16,
                          height_post: 16
                      },
                      {
                          label: 'Supprimer',
                          action: '',
                          picto: 'Trash2',
                          viewBox: '0 0 24 24',
                          width: 12,
                          height: 12,
                          picto_post: '',
                          viewBox_post: '0 0 24 24',
                          width_post: 16,
                          height_post: 16
                      },
                  ]
              }
          },
      },"notes":"\n          active: défaut true. Passer à false pour désactiver le menu. Il passera en opacité 0.5 et impossible à cliquer.\n          autoclose: défaut true. Passer à false pour ne pas fermer le menu lors d'un click sur un élément du sous-menu.\n          context: défaut {}. Permet de passer un objet contextuel qui sera passé à l'action de chaque élément du sous-menu.\n          droite: défaut false. Passer à true aligner le sous-menu sur la droite du bouton.\n          settings: défaut {}. Défini l'arborescence du menu\n      "}}),_c('Show_Component',{attrs:{"path":"common/ui/menu_sous-menu","currentComponent":_vm.composant_menu_sous_menu,"params":{
          settings : {
              menu: {
                  label: 'Select',
                  arrow: true
              },
              sousmenu: {
                  class: '',
                  list: [
                      {
                          label: 'Choix 1',
                          action: () => this.affiche('Sélection du choix 1'),
                          _action: '() => this.affiche(Sélection du choix 1)',
                      },
                      {
                          label: 'Choix 2',
                          action: () => this.affiche('Sélection du choix 2'),
                          _action: '() => this.affiche(Sélection du choix 2)',
                      },
                      {
                          class: 'separator'
                      },
                      {
                          label: 'Choix 3',
                          action: () => this.affiche('Sélection du choix 3'),
                          _action: '() => this.affiche(Sélection du choix 3)',
                      },
                      {
                          label: 'Choix 4',
                          action: () => this.affiche('Sélection du choix 4'),
                          _action: '() => this.affiche(Sélection du choix 4)',
                      },
                      {
                          class: 'separator'
                      },
                      {
                          class: 'action-green',
                          label: 'Configurer',
                          action: () => this.affiche('CONFIGURATION'),
                          _action: '() => this.affiche(CONFIGURATION)',
                          picto_post: 'Plus'
                      }
                  ]
              }
          },
      },"notes":"\n          active: défaut true. Passer à false pour désactiver le menu. Il passera en opacité 0.5 et impossible à cliquer.\n          autoclose: défaut true. Passer à false pour ne pas fermer le menu lors d'un click sur un élément du sous-menu.\n          context: défaut {}. Permet de passer un objet contextuel qui sera passé à l'action de chaque élément du sous-menu.\n          droite: défaut false. Passer à true aligner le sous-menu sur la droite du bouton.\n          settings: défaut {}. Défini l'arborescence du menu\n          .\n          Insérer un élément avec la class 'separator' pour créer une ligne de séparation.\n          Utiliser la class 'action-green' pour créer un menu d'action vert.\n          .\n          /!\\ après ouverture des détails, les actions du menu ne peuvent plus fonctionner, relancer la page de composant pour réinitialiser (transformation en Json qui perd les functions)\n      "}}),_c('Show_Component',{attrs:{"path":"common/ui/menu_sous-menu","currentComponent":_vm.composant_menu_sous_menu,"params":{
          settings : {
              menu: {
                  picto: 'Setting',
                  viewBox: '0 0 48 48',
                  width: 16,
                  height: 16,
                  label: 'Select',
                  arrow: true
              },
              sousmenu: {
                  class: '',
                  list: [
                      {
                          label: 'Choix 1'
                      },
                      {
                          label: 'Choix 2'
                      }
                  ]
              }
          },
      },"notes":"\n          active: défaut true. Passer à false pour désactiver le menu. Il passera en opacité 0.5 et impossible à cliquer.\n          autoclose: défaut true. Passer à false pour ne pas fermer le menu lors d'un click sur un élément du sous-menu.\n          context: défaut {}. Permet de passer un objet contextuel qui sera passé à l'action de chaque élément du sous-menu.\n          droite: défaut false. Passer à true aligner le sous-menu sur la droite du bouton.\n          settings: défaut {}. Défini l'arborescence du menu\n          .\n          Insérer un élément avec la class 'separator' pour créer une ligne de séparation.\n          Utiliser la class 'action-green' pour créer un menu d'action vert.\n          .\n          /!\\ après ouverture des détails, les actions du menu ne peuvent plus fonctionner, relancer la page de composant pour réinitialiser (transformation en Json qui perd les functions)\n      "}}),_c('Show_Component',{attrs:{"path":"common/ui/menu_sous-menu","currentComponent":_vm.composant_menu_sous_menu,"params":{
          class: 'condensed',
          settings : {
              menu: {
                  label: 'Select',
                  arrow: true
              },
              sousmenu: {
                  list: [
                      {
                          label: 'Choix 1'
                      },
                      {
                          label: 'Choix 2'
                      }
                  ]
              }
          },
      },"notes":"\n          poser la class 'condensed' sur l'appel du composant pour réduire la taille du menu\n      "}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }